import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { authHeader } from '../util/service';

export default function Wallet(props) {
    const [nfts, setNFTs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        connectWallet();
    }, []);

    const connectWallet = async () => {
        setNFTs([])
        try {
            // Ensure Phantom is installed
            if (!window.solana) {
                setError('Phantom Wallet not detected. Please install Phantom Wallet in your browser.')
                setLoading(false)
                props.setNftCollection(undefined)
                return;
            }

            // Connect to the wallet
            const { publicKey } = await window.solana.connect();

            // Connect to the Solana network with Quicknode added as RPC
            /*const network = clusterApiUrl('testnet');
            const connection = new Connection(network, 'confirmed');*/

            //let rpcEndpoint = 'https://soft-dawn-reel.solana-mainnet.quiknode.pro/cb957a6fed43fc8d32a6e0a118e4b1f90914c920/';
            //const connection = new Connection(rpcEndpoint);

            // Fetch the user's NFTs from the Solana blockchain
            const ownerAddress = publicKey.toString();

            getTokenAccounts(ownerAddress);
        } catch (error) {
            setError('Error connecting wallet')
        }
    };

    const getTokenAccounts = async (wallet, solanaConnection) => {
        /*const accounts = await solanaConnection.getParsedProgramAccounts(
            TOKEN_PROGRAM_ID, // new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA")
            {
                filters: [
                    {
                        dataSize: 165, // number of bytes
                    },
                    {
                        memcmp: {
                            offset: 32, // number of bytes
                            bytes: wallet, // base58 encoded string
                        },
                    },
                ],
            }
        );*/

        if (!wallet) return;

        console.log('wallet', wallet)

        axios.get(`https://versemail.azurewebsites.net/wallet/${wallet}`, { headers: authHeader() }).then(response => {
            if (response?.data) {
                props.setNftCollection(response?.data?.assets)
                setNFTs(response?.data?.assets?.length ? response?.data?.assets : null)
            }
        }).catch(error => {
            props.setNftCollection(null)
            setNFTs(null)
        });
    }

    if (error)
        return (
            <div className='gallery-msg'>
                <div>
                    {error}
                </div>
            </div>
        );
    else if (!nfts?.length) {
        return (
            <>
                <div className='phantom-sync' onClick={connectWallet}>
                    <i class='bx bxs-ghost' />

                    <div>
                        Sync your Phantom Wallet
                    </div>
                </div>

                <div className='gallery-msg'>
                    <div>{nfts !== null ? 'Loading...' : 'No results'}</div>
                </div>
            </>
        )
    } else {
        return (
            <div className='phantom-sync' onClick={connectWallet}>
                <i class='bx bxs-ghost' />

                <div>
                    Sync your Phantom Wallet
                </div>
            </div>
        )
    }
}