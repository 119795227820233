import React, { useRef, useState } from 'react'
import { authHeader, getEmailFromTokenVerify, sendOTP } from '../util/service'
import { Outlet, Link, useParams } from "react-router-dom";
import axios from 'axios';
import OtpInput from 'react-otp-input';

// not used anymore
export default function ForgotPasswordReset(props) {
    const [otp, setOtp] = useState('');
    const [isOtpResent, setIsOtpResent] = useState(false);
    const [error, setError] = useState(undefined);
    const [time, setTime] = useState(10);
    let { token } = useParams();
    let interval = useRef(null);

    const handleInput = (value) => {
        setOtp(value);
        setError(undefined)
        if (value?.length >=4) {
            verifyAccount(token, value)
        }
    }

    const resendOTP = (e) => {
        e.preventDefault();

        if (isOtpResent) return;

        sendOTP(getEmailFromTokenVerify(token));
    }

    const sendOTP = (login) => {
        axios.post(`https://versemail.azurewebsites.net/playfab/profile/password/request`, { login }).then(response => {
            if (response) {
                initStates();
                
                setIsOtpResent(true);

                let newTime = time;
                interval.current = setInterval(() => {
                    if (newTime <= 0) {
                        clearInterval(interval.current);
                        setTime(10);
                        setIsOtpResent(false)
                    } else {
                        setTime(newTime--);
                    }

                }, 1000)
            } else {
                setError('Error sending code.')
            }
        }).catch(error => {
            setError('Error sending code.')
        });
    }
    
    const verifyAccount = (token, otp) => {
        return axios.post(`https://versemail.azurewebsites.net/playfab/profile/password/verify`, { otp }, { headers: {'x-access-token': token} }).then(response => {
            if (response?.data?.playFabId) {
                initStates();

            } else {
                setError('Error with your code.')
            }
        }).catch(error => {
            setError('Error with your code.')
        });
    }

    const initStates = () => {
        setOtp('');
        setIsOtpResent(false)
        setTime(10);
        setError(undefined);
        clearInterval(interval.current);
    }

    const renderOTP = () => {
        return (
            <div className='otp-container'>
                <label>Enter Code</label>

                <OtpInput
                    value={otp}
                    onChange={handleInput}
                    numInputs={4}
                    separator={<span>-</span>}
                    shouldAutoFocus={true}
                />

                {
                    error ? <div className='text-explainer otp resend error'>{error}</div> :
                    isOtpResent ?
                        <div className='text-explainer otp resend'>Code resent, please wait {time} seconds to retry...</div> :
                        <a href='#' className='text-explainer otp' onClick={(e) => resendOTP(e)}>Send another code via email</a>
                }
            </div>
        );
    }

    return (
        <div className='login'>
            <h3>Forgot Password?</h3>
            <div className='text-explainer'>
                To help keep your account safe, The Verse company wants to make sure it’s really you trying to reset your password.
            </div>

            <div className='text-explainer'>
                An email with a verification code was sent to 
                <b> {getEmailFromTokenVerify(token)}</b>
            </div>

            {renderOTP()}
        </div>
    )
}
